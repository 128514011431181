<template>
  <v-data-table
    :headers="headers"
    :items="packages"
    :search="search"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
        <v-dialog
        v-model="dialog"
        max-width="700">
        <template v-slot:activator="{ on, attrs }">
          <!-- <div class="d-flex justify-space-between"> -->
          <!-- <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> -->
          <!--            <v-btn-->
          <!--              color="primary"-->
          <!--              dark-->
          <!--              class="mb-2"-->
          <!--              v-bind="attrs"-->
          <!--              v-on="on"-->
          <!--            >-->
          <!--              เพิ่มคะแนน-->
          <!--            </v-btn>-->
          <!-- </div> -->
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <table class="table">
                    <thead>
                    <tr>
                      <th class="text-center">#</th>
                      <th>ชื่อแพ็กเกจ</th>
                      <th>จำนวนชิ้น</th>
                      <th>ราคา</th>
                      <th>จำนวน</th>
                      <th>รวม</th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item,index) in selected_packages">
                      <td class="text-center">{{ index + 1 }}</td>
                      <td>{{ item.package_name }}</td>
                      <td>{{(item.package_item_amt ? item.package_item_amt : 0) }}</td>
                      <td>{{ item.package_price }}</td>
                      <td>
                        <v-text-field
                          @keyup="changeAmount(index,item.amount)"
                          min="1"
                          v-model="item.amount"
                          type="number"
                        ></v-text-field>
                      </td>
                      <td>
                        {{item.total}}
                      </td>
                      <td>
                        <v-icon
                          small
                          @click="deleteItem(index)"
                        >
                          {{ icons.mdiDelete }}
                        </v-icon>
                      </td>
                    </tr>
                    </tbody>

                  </table>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <h2 class="text-right">จำนวนเงิน : {{ parseInt(packageItemAmt) }} ชิ้น</h2>
                  <h2 class="text-right">ยอดรวมทั้งหมด : {{ parseFloat(total).toFixed(2) }}
                    บาท</h2>

                  <!--                    <h4 > จำนวนเงิน : @{{parseInt(packageItemAmt )}} (บาท)</h4>-->
                  <!--                    <h4 style="color:rgb(227, 86, 83)"> ยอดรวมทั้งหมด: @{{parseFloat(total).toFixed(2)}} (บาท)</h4>-->
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12">

                  <v-card>
                    <v-card-text>
                      <h2>ลูกค้า (ชื่อลูกค้า/ชื่อร้าน)</h2>
                      <v-select
                        placeholder="เลือกลูกค้า"
                        v-model="editedItem.user_id"
                        item-text="member_name"
                        item-value="member_id"
                        :items="members"
                      ></v-select>
                      <small
                        class="validate-err"
                        v-for="(err,index) in v$.editedItem.user_id.$silentErrors"
                        v-if="v$.editedItem.user_id.$error">{{ index > 0 ? ', ' : '' }}
                        {{ err.$message }}</small>

                    </v-card-text>
                  </v-card>
                </v-col>


                <v-col
                  cols="12"
                  sm="12"
                  md="12">
                  <v-card>
                    <v-card-text>
                      <h2>วิธีการจ่าย</h2>
                      <v-radio-group
                        v-model="editedItem.type_pay"
                      >
                        <v-radio value="1" label="โอนเงิน"></v-radio>
<!--                        <v-radio value="4"-->
<!--                                 disabled-->
<!--                                 label="QR Code"></v-radio>-->
<!--                        <v-radio value="3"-->
<!--                                 disabled-->
<!--                                 label="เครดิตเงินในกระเป่าออนไลน์"></v-radio>-->
<!--                        <v-radio value="2"-->
<!--                                 disabled-->
<!--                                 label="บัตรเคดิต/บัตรเดบิต"></v-radio>-->
<!--                        <v-radio value="6"-->
<!--                                 disabled-->
<!--                                 label="ผ่อนชำระรายเดือน 0% ขั้นนต่ำ 3500 บาท"></v-radio>-->
                        <v-radio value="5" label="เงินสด"></v-radio>
                      </v-radio-group>

                      <div v-if="editedItem.type_pay == 1">
                        <v-select
                          label="ธนาคาร"
                          :items="banks"
                          v-model="editedItem.bank_id"
                          item-text="BankName"
                          item-value="BankID"
                        ></v-select>
                        <small
                          class="validate-err"
                          v-for="(err,index) in v$.editedItem.bank_id.$silentErrors"
                          v-if="v$.editedItem.bank_id.$error">{{ index > 0 ? ', ' : '' }}
                          {{ err.$message }}</small>
                        <v-file-input
                          v-model="editedItem.file"
                          label="อัพโหลดไฟล์หลักฐานการโอน *"
                        >
                        </v-file-input>
                        <small
                          class="validate-err"
                          v-for="(err,index) in v$.editedItem.file.$silentErrors"
                          v-if="v$.editedItem.file.$error">{{ index > 0 ? ', ' : '' }}
                          {{ err.$message }}</small>

                        <v-text-field
                          min="0"
                          type="number"
                          v-model="editedItem.amount_pay"
                          label="จำนวนเงิน"
                        ></v-text-field>
                        <small
                          class="validate-err mb-5"
                          v-for="(err,index) in v$.editedItem.amount_pay.$silentErrors"
                          v-if="v$.editedItem.amount_pay.$error">{{ index > 0 ? ', ' : '' }}
                          {{ err.$message }}</small>

                        <div>
                        <DatePicker
                          class="mt-5 mb-5"
                          v-model="editedItem.pay_date"
                          value-type="format"
                          format="YYYY-MM-DD"
                          label="แจ้งวันที่โอน"
                          placeholder="แจ้งวันที่โอน"
                        ></DatePicker>
                          </div>

                        <div>
                        <small
                          class="validate-err mt-5 mt-10"
                          v-for="(err,index) in v$.editedItem.pay_date.$silentErrors"
                          v-if="v$.editedItem.pay_date.$error">{{ index > 0 ? ', ' : '' }}
                          {{ err.$message }}</small>
                        </div>

                        <v-text-field
                          type="time"
                          v-model="editedItem.pay_time"
                          label="แจ้งเวลาที่โอน"
                        ></v-text-field>
                        <small
                          class="validate-err"
                          v-for="(err,index) in v$.editedItem.pay_time.$silentErrors"
                          v-if="v$.editedItem.pay_time.$error">{{ index > 0 ? ', ' : '' }}
                          {{ err.$message }}</small>

                        <div
                          style="color: red;font-size: 12px;">*โปรดระบุจำนวนเงินและแจ้งวันเวลาที่โอน ให้ถูกต้องเพื่อการตรวจสอบที่ง่ายขึ้นต่อระบบการยืนยันการสั่งซื้อแพ็กเกจ</div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              text
              @click="close"
            >
              ยกเลิก
            </v-btn>
            <v-btn
              v-if="selected_packages && selected_packages.length > 0"
              color="primary"
              text
              @click="save">
              ยืนยีน
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.index="{ index }">
      {{ index + 1 }}
    </template>
    <template v-slot:item.actions="{ item }">

      <v-btn
        @click="choosePackage(item)"
        color="primary">
        เติมเงินเข้าระบบ
      </v-btn>
<!--      <v-icon-->
<!--        small-->
<!--        class="mr-2"-->
<!--        @click="editItem(item)"-->
<!--      >-->
<!--        {{ icons.mdiPencil }}-->
<!--      </v-icon>-->
<!--      <v-icon-->
<!--        small-->
<!--        @click="deleteItem(item)"-->
<!--      >-->
<!--        {{ icons.mdiDelete }}-->
<!--      </v-icon>-->
    </template>
    <template v-slot:no-data>
      <!-- <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>

<script>
import { mdiPencil, mdiDelete } from '@mdi/js'
// import config from '../../../config'
import instance from '../../../axios.service'
import instance_member from "@/services/member";
import instance_bank from "@/services/bank";
import instance_payment_type from '../../../services/payment'
import Swal from "sweetalert2";
import {useVuelidate} from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import DatePicker from "vue2-datepicker";
import instance_package from "@/services/package";

export default {
  components: {DatePicker},
  setup() {
    return {
      v$: useVuelidate(),
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  validations() {
    var valid = {
      editedItem: {
        user_id: {required},
        type_pay: {required},
        bank_id: {},
        file: {},
        amount_pay: {},
        pay_date: {},
        pay_time: {},
      }
    }
    if (this.editedItem.type_pay == 1){
      valid.editedItem.bank_id = {required};
      valid.editedItem.file = {required};
      valid.editedItem.amount_pay = {required};
      valid.editedItem.pay_date = {required};
      valid.editedItem.pay_time = {required};
    }
    return valid
  },
  data: () => ({
    selected_packages: [],
    packages: [],
    members: [],
    banks: [],
    dialog: false,
    dialogDelete: false,
    search: '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index',
        sortable: false
      },
      {text: 'ประเภทแพ็กเกจ', value: 'product_type.product_type_name', sortable: false},
      {text: 'ชื่อแพ็กเกจ', value: 'package_name'},
      {text: 'ราคา', value: 'package_price'},
      {text: 'จำนวน', value: 'package_price_all'},
      {text: 'ราคาต่อชิ้น', value: 'price_pe_item'},
      {text: 'รายละเอียด', value: 'package_detail'},
      {text: '', value: 'actions', sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      user_id: '',
      type_pay: '1',
      data: [],
      amount: '',
      total_price: '',
      bank_id: '',
      file: null,
      amount_pay: '',
      pay_date: '',
      pay_time: '',
    },
    defaultItem: {
      user_id: '',
      type_pay: '1',
      data: [],
      amount: '',
      total_price: '',
      bank_id: '',
      file: null,
      amount_pay: '',
      pay_date: '',
      pay_time: '',
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? '  สรุปรายการแพ็กเกจ' : ' สรุปรายการแพ็กเกจ'
    },

    packageItemAmt() {
      let total = [];

      Object.entries(this.selected_packages).forEach(([key, val]) => {
        console.log(val.package_item_amt , val.amount)
        if (val.package_price && val.amount) {
          total.push(parseInt(val.package_item_amt * val.amount)) // the value of the current key.
        } else {
          total.push(0)
        }

      });

      var result = total.reduce(function (total, num) {
        return parseInt(total) + parseInt(num)
      }, 0);
      return result;
    },
    total_price () {
      let total = [];

      Object.entries(this.selected_packages).forEach(([key, val]) => {
        if (val.promotion) {
          total.push(parseInt(val.promotion)) // the value of the current key.
        } else {
          total.push(0)
        }

      });


      return total.reduce(function (total, num) {
        return parseInt(total) + parseInt(num)
      }, 0);
    },
    total() {

      let total = [];

      Object.entries(this.selected_packages).forEach(([key, val]) => {
        if (val.total) {
          total.push(parseInt(val.total)) // the value of the current key.
        } else {
          total.push(0)
        }

      });


      return total.reduce(function (total, num) {
        return parseInt(total) + parseInt(num)
      }, 0);

    },

    amount: function () {

      let total = [];

      Object.entries(this.order_cart).forEach(([key, val]) => {
        if (val.amount) {
          total.push(parseInt(val.amount)) // the value of the current key.
        } else {
          total.push(0)
        }

      });


      return total.reduce(function (total, num) {
        return parseInt(total) + parseInt(num)
      }, 0);

    },
    promotion: function () {

      let total = [];

      Object.entries(this.selected_packages).forEach(([key, val]) => {
        if (key === "0") {
          // if (val.percent_promotion && '{{date('Y-m-d')}}' > val.percent_promotion) {
          // if (val.percent_promotion && '{{date('Y-m-d')}}' > val.percent_promotion) {
          //   total.push(parseInt(val.percent_promotion)) // the value of the current key.
          //
          // } else {
          total.push(0)
          // }

        }


      });


      return total.reduce(function (total, num) {
        return parseInt(total) + parseInt(num)
      }, 0);

    }
  },

  watch: {
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    // this.getUser()
    this.getPackage()
    this.getMember()
    this.getBank()
  },

  methods: {
    changeAmount(i,amount){
      var tmp = this.selected_packages[i];
      tmp.amount = amount;
      tmp.total = amount * this.selected_packages[i].package_price
      this.selected_packages.splice(i,1,tmp)
    },
    choosePackage(item) {
      this.v$.$reset();
      if (!item.amount) {
        item.amount = 1
      }
      var already = false;
      Object.entries(this.selected_packages).forEach(([key, val]) => {
        if (val.package_id == item.package_id) {
          already = key
          return true
        }
      });


      // var  check = true ;
      // Object.entries( this.order_cart).forEach(([key, val]) => {
      //   if(val.package_id  === this.data[index].package_id){
      //     this.order_cart[key].amount = this.order_cart[key].amount+1
      //     check = false;
      //     this.order_cart[key].total = (this.order_cart[key].package_price* this.order_cart[key].amount - ( this.order_cart[key].package_price* this.order_cart[key].amount)/100* this.order_cart[key].promotion)
      //     this.order_cart[key].package_item_amt = this.order_cart[key].package_item_amt * this.order_cart[key].amount
      //   }
      // })

      if (already) {
        this.selected_packages[already].amount++;

        //     check = false;
        this.selected_packages[already].total = (this.selected_packages[already].package_price* this.selected_packages[already].amount - ( this.selected_packages[already].package_price* this.selected_packages[already].amount)/100* this.selected_packages[already].promotion)
        this.selected_packages[already].package_item_amt = this.selected_packages[already].package_item_amt * this.selected_packages[already].amount
      } else {
        item.total = (item.package_price * 1 - ( item.package_price* 1)/100* (item.percent_promotion ?? 0))
        item.package_item_amt = item.package_item_amt * item.amount
        this.selected_packages.push(item);
      }

      this.dialog = true
    },
    editItem(item) {
      this.editedIndex = this.product_points.indexOf(item)
      this.editedItem = { ...item }
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.packages.indexOf(item)
      this.editedItem = { ...item }
      this.dialogDelete = true
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    async save() {
      let $this = this;
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return
      // check customer
      // check type pay == 1 validate
      // check type pay == 3 validate
      // this.checkCredit();



      // if (this.type_pay === 6 || this.type_pay === '6' && (!this.transaction || !this.endTerm) && this.customer_select) {
      //   Swal.fire({
      //     text: 'กรุณากรอกข้อมูลให้ครบถ้วน',
      //     timer: 3000,
      //     icon: 'error',
      //     showConfirmButton: false,
      //     button: false
      //   })
      //   this.check = false;
      //   return false
      // }


      // if ((this.type_pay === 2 || this.type_pay === '2' || this.type_pay === 3 || this.type_pay === '3' || this.type_pay === 6 || this.type_pay === '6') && this.total > 30000) {
      //   this.check = false;
      //   Swal.fire({
      //     text: 'จำนวนเงินที่ชำระมากเกินไป กรุณาแจ้งโอน สลิป',
      //     timer: 3000,
      //     icon: 'error',
      //     showConfirmButton: false,
      //     button: false
      //   })
      //   return false
      // }


      // data.append('data', JSON.stringify(this.order_cart));
      // data.append('create_order', 'addmin');
      // data.append('user_id', this.customer_select.member_id);
      // data.append('type_pay', this.type_pay);

      // if (this.type_pay === 6 || this.type_pay === '6') {
      //   data.append('month', this.month);
      // }
      // data.append('amount', this.packageItemAmt);
      // if (this.type_pay === 1 || this.type_pay === '1') {
      //   data.append('bank_id', this.bank.BankID);
      //   data.append('file', this.slip);
      //   data.append('amount_pay', this.amount_pay);
      //   data.append('pay_date', this.pay_date);
      //   data.append('pay_time', this.pay_time);
      //   data.append('month', this.month);
      // }

      // if (this.type_pay === 6 || this.type_pay === '6') {
      //   let bankType = this.transaction.label;
      //   let endTerm = this.endTerm;
      //   data.append('bankType', bankType);
      //   data.append('endTerm', endTerm);
      // }

      Swal.fire({
        title: "ยืนยันการสั่งซื้อแพ็กเกจ",
        text: "",
        icon: "warning",
        confirmButtonText : 'ยืนยัน',
        cancelButtonText : 'ยกเลิก',
        showCancelButton : true,
        dangerMode: true,
      })
        .then((willDelete) => {
          if (willDelete.isConfirmed) {
            this.$store.state.isLoading = true;
            this.editedItem.total_price = this.total
            this.editedItem.amount = this.packageItemAmt
            this.editedItem.create_order = 'addmin'
            this.editedItem.product_type_id = this.selected_packages[0].product_type_id;
            instance_package.addPackage(this.editedItem, this.selected_packages).then(function (res) {
              if(false){
                // if (res.data && (this.editedItem && (this.editedItem.type_pay == 2 || this.editedItem.type_pay == 3 || this.editedItem.type_pay == 6))){
                // if (res.data && res.data && (res.data.data.id && this.editedItem.type_pay === 2 || this.editedItem.type_pay === '2' || this.editedItem.type_pay === 3 || this.editedItem.type_pay === '3' || this.editedItem.type_pay === 6 || this.editedItem.type_pay === '6')) {
                // var id = response.data.data.id
                // if (id && $this.type_pay === 3 || $this.type_pay === '3') {
                //   location.href = '{{url('paymentagainQR')}}' + '/' + id + '?type=MemberPackageCredit'`
                // }
                // if (id && $this.type_pay === 2 || $this.type_pay === '2') {
                //   location.href = '{{url('paymentagainCard')}}' + '/' + id + '?type=MemberPackageCredit'
                // }
                // if (id && $this.type_pay === 6 || $this.type_pay === '6') {
                //   location.href = '{{url('paymentagainInstallment')}}' + '/' + id + '?type=MemberPackageCredit'
                // }

              } else {
                $this.$store.state.isLoading = false;

                setTimeout(function () {
                  Swal.fire({
                    text: 'ซื้อแพ็กเกจสำเสร็จ',
                    timer: 3000,
                    icon: 'success',
                    showConfirmButton: false,
                    button: false
                  })
                },150);
                $this.$router.push({'name': 'pages-report-add-package'})
              }
            })
              .catch(function (error) {
                $this.$store.state.isLoading = false;
                Swal.fire({
                  text: error.message,
                  timer: 3000,
                  icon: 'error',
                  showConfirmButton: false,
                  button: false
                })
              });
          } else {
            this.$store.state.isLoading = false
            Swal.fire("แน่ใจเเล้วสามารถยืนยันการสั่งซื้อแพ็กเกจได้ทันที");

          }
        });

    },
    getPackage() {
      var data = {
        search: '',
        date: '',
        status: 'Y',
        size : 99999,
        page : 1
      }
      this.$store.state.isLoading = true;
      instance_package.getPackage(data).then(res => {
        this.packages = res.data.data.data;
        this.$store.state.isLoading = false;
      }).catch(err => {
        this.$store.state.isLoading = false;
      });
    },
    getMember() {
      var data = {
        search: '',
        sortDesc: '',
        sortBy: '',
        page: 1,
        size: 9999999
      }

      instance_member.getMember(data).then(res => {
        this.members = res.data.data.data;
      }).catch(err => {

      });
    },
    getBank() {
      var data = {
        search: '',
        date: '',
        status: '',
      }

      instance_bank.get(data).then(res => {
        this.banks = res.data;
      }).catch(err => {

      });
    },
    getUser() {
      // eslint-disable-next-line no-useless-concat
      const baseURL = `${process.env.VUE_APP_BASEURL}` + '/login'
      instance
        .get(`${baseURL}user?search=`)
        .then(res => {
          if (res.status === 200) {
            this.users = res.data.data
          }

          // Unauthorized Logout
          // eslint-disable-next-line no-empty
          if (res.status === 200 && res.data.error === 'Unauthorized') {
            localStorage.removeItem('token_authentication')
          }
        })
        .catch(error => {
          console.log(error)

        })
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
